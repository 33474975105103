<template>
  <b-container fluid>
    <b-row>
      <b-col cols="12" md="4" class="mb-4" style="color: white;">
        <div class="text-left py-1">
          <router-link to="/admin/employee" class="mx-2">Employees</router-link> /
          <span style="color:lightgrey;" class="mx-2">
            {{ user.firstname }} {{ user.lastname }}
          </span>
        </div>
      </b-col>
      <b-col cols="12" md="4" class="mb-4">
        <b-button
          @click="disable()"
          v-if="user.active == 1"
          variant="danger"
          block
        >
          <b-icon icon="x-circle"></b-icon>
          Disable
        </b-button>
        <b-button
          @click="enable()"
          v-else
          variant="info"
          block
        >
          <b-icon icon="arrow-clockwise"></b-icon>
          Re-activate
        </b-button>
      </b-col>
      <b-col cols="12" md="4" class="mb-4">
        <b-button
          @click="resetPassword()"
          variant="success"
          block
        >
          <b-icon icon="key-fill"></b-icon>
          Reset Password
        </b-button>
      </b-col>
    </b-row>
    <UserDetails
      :user="this.user"
    >
      <template>
        <EditUser @refresh="init()" :id="+id" edit></EditUser>
      </template>
    </UserDetails>
  </b-container>
</template>
<script>
import AdminService from '@/services/AdminService'

import EditUser from '@/components/admin/EmployeeModal'
import UserDetails from '@/components/admin/UserDetails'

export default {
  data () {
    return {
      organization: 'Organization',
      user: {}
    }
  },

  components: {
    EditUser,
    UserDetails
  },

  props: {
    id: {
      type: [Number, String],
      default: 0
    }
  },

  created () {
    this.init()
  },

  methods: {
    init () {
      const filters = {
        id: this.id,
        organization_id: null,
        company_id: null
      }

      AdminService.getUsers(filters).then(
        (response) => {
          this.user = response.data.info.users[0]
        }
      )
    },

    disable () {
      const data = {
        active: 0,
        id: this.id
      }
      AdminService.updateEmployee(data).then(
        (response) => {
          this.$aimNotify.notify(response)
          this.$nextTick(() => {
            this.init()
          })
        }
      )
    },

    enable () {
      const data = {
        active: 1,
        id: this.id
      }
      AdminService.updateEmployee(data).then(
        (response) => {
          this.$aimNotify.notify(response)
          this.$nextTick(() => {
            this.init()
          })
        }
      )
    },

    resetPassword () {
      AdminService.resetPassword(this.id).then(
        (response) => {
          this.$aimNotify.notify(response)
        }
      )
    }
  }
}
</script>
